import {db} from "../../firebase";
import {collection, doc, endBefore, limit, query, startAfter, where} from "firebase/firestore";
import {getByQuery, getOne} from "../../services/Projects/DatabaseService";


const getAll = async ({deleted_at}) => {
    const projectRequestDocRef = collection(db, "projects_request")
    let dataQuery = query(projectRequestDocRef, where("deleted_at", "==", deleted_at))
    return getByQuery(dataQuery)
}
const getProjectRequestById = ({id, deleted_at = false}) => {
    return getOne(doc(db, "projects_request",id))
}
const getProjectDetailsById = ({id, deleted_at = false}) => {
    return  getOne(doc(db, "projects_request_details", id))
}

const getFirstPage = async ({perPage, deleted_at}) => {

    const projectRequestDocRef = collection(db, "projects_request")

    let dataQuery = query(projectRequestDocRef,
        where("deleted_at", "==", deleted_at),
        limit(perPage)
    )
    return getByQuery(dataQuery)
}

const getNextPage = async ({perPage, lastItem, deleted_at}) => {

    const projectRequestDocRef = collection(db, "projects_request_request")

    let dataQuery = query(projectRequestDocRef,
        where("deleted_at", "==", deleted_at),
        startAfter(lastItem),
        limit(perPage)
    )
    return getByQuery(dataQuery)
}

const getPrevPage = async ({perPage, lastItem, deleted_at}) => {

    const projectRequestDocRef = collection(db, "projects_request")
    let dataQuery = query(projectRequestDocRef,
        where("deleted_at", "==", deleted_at),
        endBefore(lastItem),
        limit(perPage)
    )
    return getByQuery(dataQuery)
}


export {getAll, getProjectRequestById,getProjectDetailsById, getFirstPage, getNextPage, getPrevPage}
