import React from 'react'

import Message from "../../../components/inbox/Message";

function PageMessageShow() {

    return (
        <Message/>
    )
}

export default PageMessageShow
