import ProjectsGrid from '../../components/projects/ProjectsGrid';
import {useRecoilState} from "recoil";
import {projectsList} from "../../states/Projects";
import {useEffect} from "react";
import {getAll} from "../../Repositories/Admin/ProjectRepository";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";

const Projects = () => {
	const [projects,setProjects] = useRecoilState(projectsList)
	//const [isLoading, setIsLoading] = useState(true)

	useEffect(()=>{
		// getFirstPage({perPage:perPage,deleted_at:false})
		getAll({deleted_at:false})
			.then(({data,lastKey})=>{
				setProjects(data)
				//setIsLoading(false)
			})
	},[setProjects])
	return (
		<>
			<Helmet title="HichamHr | Projects"/>
			<div className="container mx-auto">
				<motion.div
					initial={{opacity: 0}}
					animate={{opacity: 1, delay: 1}}
					exit={{opacity: 0}}
					className="container mx-auto"
				>
					<ProjectsGrid projects={projects}  />
				</motion.div>
			</div>
		</>
	);
};

export default Projects;
