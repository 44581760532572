import { Link } from 'react-router-dom';
import AppBanner from '../../components/Shared/AppBanner';
import ProjectsGrid from '../../components/projects/ProjectsGrid';
import {useRecoilState} from "recoil";
import {projectsList} from "../../states/Projects";
import {useEffect} from "react";
import {getAll} from "../../Repositories/Admin/ProjectRepository";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";


const Home = () => {
	const [projects,setProjects] = useRecoilState(projectsList)
//	const [isLoading, setIsLoading] = useState(true)

	useEffect(()=>{
		// getFirstPage({perPage:perPage,deleted_at:false})
		getAll({deleted_at:false})
			.then(({data,lastKey})=>{
				setProjects(data)
		//		setIsLoading(false)
			})
	},[setProjects])
	return (
		<>
			<Helmet title="HichamHr | Home"/>
			<motion.div
				initial={{opacity: 0}}
				animate={{opacity: 1, delay: 1}}
				exit={{opacity: 0}}
				className="container mx-auto"
			>
				<div className="container mx-auto">
					<AppBanner/>
					<ProjectsGrid projects={projects}/>
					<div className="mt-8 sm:mt-10 flex justify-center">
						<Link
							to="/projects"
							className="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
							aria-label="More Projects"
						>
							More Projects
						</Link>
					</div>
				</div>

			</motion.div>
		</>
	);
};

export default Home;
